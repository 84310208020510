import React, { memo } from "react";
// import { useSelector, useDispatch } from "react-redux";

import RecordsTable from "./recordsTable";

function RecordsList(props) {
  const {
    list,
    filterBy,
    getRecordsCount,
    // setRecordsCount
  } = props;

  // const getAllkeys = Object.keys(getRecordsCount);

  // console.log("getAllkeys", filterBy, filterBy.value === "all");

  return (
    <div className="record-list-container">
      {filterBy.value === "all" ? (
        list.map((listItem, i) => (
          <RecordsTable
            key={listItem.id}
            title={listItem.title}
            data={getRecordsCount[listItem?.value]}
          />
        ))
      ) : (
        <RecordsTable
          title={filterBy.title}
          data={getRecordsCount[filterBy.value]}
        />
      )}
    </div>
  );
}

export default memo(RecordsList);
