import React, { useState, useCallback } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";

import { auth } from "../../config/fb";
import WelAuth from "./welAuth";

const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const schema = yup
  .object()
  .shape({
    email: yup
      .string()
      .trim()
      .required("Required")
      .email("Invalid email")
      .matches(emailRegex, "Invalid email"),
    password: yup.string().min(6),
  })
  .required();

export default function SignUp() {
  const [isChecked, setIsChecked] = useState(false);
  const [viewPass, setViewPass] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    pass: "",
    email: "",
  });

  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data, e) => {
    e.preventDefault();
    const { email, password } = data;
    await createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        const user = userCredential.user;
        if (user) {
          await sendEmailVerification(user);
          navigate("/");
        }
      })
      .catch((error) => {
        const errorCode = error.code;
        switch (errorCode) {
          case "auth/wrong-password":
            setErrorMessage({ ...errorMessage, pass: "incorrect password" });
            break;
          case "auth/user-not-found":
            setErrorMessage({ ...errorMessage, email: "email user not found" });
            break;
          default:
            break;
        }
      });
  };

  const handleOnChange = useCallback(() => {
    setIsChecked(!isChecked);
  }, [isChecked]);

  const handlePassView = useCallback(() => {
    setViewPass(!viewPass);
  }, [viewPass]);

  return (
    <WelAuth>
      <div className="relative flex flex-col justify-center h-full w-full">
        <form
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="on"
          id="sign up form"
          className="max-w-[500px] w-full mx-auto rounded-lg px-5 py-7 shadow-2xl bg-gray-900"
        >
          <h2 className="font-bold">Create a new account</h2>
          <div className="py-2">
            <label>Email</label>
            <input
              {...register("email", {
                pattern: /^\S+@\S+\.\S+$/,
                required: "Email Address is required",
              })}
              id="email"
              type="email"
              required={true}
              autoComplete="on"
              aria-invalid={errors.mail ? "true" : "false"}
              className="input-border form-input"
            />

            {errors.email && (
              <p className="pb-4 text-xs text-red-500 items-center flex pt-1">
                <AiOutlineInfoCircle size={18} className="pr-1" />{" "}
                {errors.email?.message}
              </p>
            )}
          </div>

          <div className="py-2">
            <label>Password</label>
            <input
              {...register("password", {
                min: 6,
                max: 12,
                required: "Password must be at least 6 characters",
              })}
              id="password"
              type={viewPass ? "text" : "password"}
              required={true}
              autoComplete="new-password"
              aria-invalid={errors.password ? "true" : "false"}
              className="input-border form-input"
            />

            {errors?.password && (
              <p className="pb-4 text-xs text-red-500 items-center flex pt-1">
                <AiOutlineInfoCircle size={18} className="pr-1" />{" "}
                {errors.password?.message}
              </p>
            )}
          </div>

          <div className="py-2">
            <label>Confirm Password</label>
            <input
              {...register("password", {
                min: 6,
                max: 12,
                required: "Password must be at least 6 characters",
              })}
              id="confirm password"
              type={viewPass ? "text" : "password"}
              required={true}
              autoComplete="new-password"
              aria-invalid={errors.password ? "true" : "false"}
              className="input-border form-input"
            />
            {errors?.password && (
              <p className="pb-4 text-xs text-red-500 items-center flex pt-1">
                <AiOutlineInfoCircle size={18} className="pr-1" />{" "}
                {errors.password?.message}
              </p>
            )}
          </div>

          <div className="justify-items-center">
            <input
              type="checkbox"
              className="rounded text-blue-500"
              checked={viewPass}
              onChange={handlePassView}
            />
            <label className="px-1">Show Password</label>
          </div>

          <div className="justify-items-center">
            <input
              type="checkbox"
              className="rounded text-blue-500"
              checked={isChecked}
              onChange={handleOnChange}
            />
            <label className="px-1">I agree the</label>
            <Link
              to="https://fishingfinity.com/termsAndConditions"
              target="_blank"
              rel="noopener noreferrer"
              className="text-xs transition-colors hover:text-blue-500 hover:underline"
            >
              {t("Terms and Conditions")}
            </Link>
          </div>
          <button
            className="button bg-blue-500 py-2 uppercase font-bold text-xs mt-5"
            type="submit"
          >
            Create a new account
          </button>
          <div className="justify-center items-center flex mt-5">
            <label className="px-1 text-sm">Already have an account?</label>
            <Link
              to="/login"
              className="text-blue-500 hover:text-blue-300 hover:underline"
            >
              Sign In
            </Link>
          </div>
        </form>
      </div>
    </WelAuth>
  );

  // return (
  //   <div className="flex mx-auto max-w-screen-sm w-fit sm:py-10 justify-center content-center items-center h-full">
  //     <div className="p-10 bg-gray-800 rounded-lg">
  //       <h1 className="font-bold pb-5 text-center">Create an account</h1>
  //       <h6 > Enter your details to register.</h6>
  //       <form onSubmit={handleSubmit(onSubmit)}>
  //         <div className="py-2">
  //           <input {...register("email", { pattern: /^\S+@\S+\.\S+$/, required: "Email Address is required" })}
  //             id="email"
  //             name="Email"
  //             type="email"
  //             required={true}
  //             autoComplete="on"
  //             aria-invalid={errors.mail ? "true" : "false"}
  //             className='input-border form-input'
  //           />

  //           {errors.email && <p className="pb-4 text-xs text-red-500 items-center flex pt-1">
  //             <AiOutlineInfoCircle size={18} className='pr-1' /> {errors.email?.message}</p>}
  //         </div>

  //         <div className="py-2">
  //           <input {...register("password", { min: 6, max: 12, required: "Password must be at least 6 characters" })}
  //             id="password"
  //             name="Password"
  //             type={viewPass ? "text" : "password"}
  //             required={true}
  //             autoComplete="new-password"
  //             aria-invalid={errors.password ? "true" : "false"}
  //             icon={<i className="fas fa-heart" />}
  //             className="pr-20"
  //             className='input-border form-input'
  //           />

  //           {errors?.password && <p className="pb-4 text-xs text-red-500 items-center flex pt-1">
  //             <AiOutlineInfoCircle size={18} className='pr-1' /> {errors.password?.message}</p>}
  //         </div>

  //         <div className="py-2">
  //           <input {...register("password", { min: 6, max: 12, required: "Password must be at least 6 characters" })}
  //             id="confirm password"
  //             label="Confirm Password"
  //             type={viewPass ? "text" : "password"}
  //             required={true}
  //             autoComplete="new-password"
  //             aria-invalid={errors.password ? "true" : "false"}
  //             icon={<i className="fas fa-heart" />}
  //             className="pr-20"
  //             className='input-border form-input'
  //           />
  //           {errors?.password && <p className="pb-4 text-xs text-red-500 items-center flex pt-1">
  //             <AiOutlineInfoCircle size={18} className='pr-1' /> {errors.password?.message}</p>}
  //         </div>

  //         <div className='justify-items-center'>
  //           <input
  //             type="checkbox"
  //             className="rounded text-blue-500"
  //             checked={viewPass}
  //             onChange={handlePassView}
  //           />
  //           <label className="px-1">Show Password</label>
  //         </div>

  //         <div className='justify-items-center'>
  //           <input
  //             type="checkbox"
  //             className="rounded text-blue-500"
  //             checked={isChecked}
  //             onChange={handleOnChange}
  //           />
  //           <label className="px-1">I agree the</label>
  //           <Link
  //             to="https://fishingfinity.com/termsAndConditions"
  //             target="_blank" rel="noopener noreferrer"
  //             className='text-xs transition-colors hover:text-blue-500 hover:underline'
  //           >
  //             {t("Terms and Conditions")}
  //           </Link>
  //         </div>
  //         <button className="button bg-blue-500 py-2 uppercase font-bold text-xs mt-5" type="submit">
  //           Register
  //         </button>
  //         <div className='justify-center items-center flex mt-5'>
  //           <label className="px-1 text-sm">Already have an account?</label>
  //           <Link
  //             to="/login"
  //             className='text-blue-500 hover:text-blue-300 hover:underline'
  //           >
  //             Sign In
  //           </Link>
  //         </div>
  //       </form>
  //     </div>
  //   </div>
  // );
}
