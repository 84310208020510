import React from "react";
import { useTranslation } from "react-i18next";

function TermsAndConditionScreen() {
  const { t } = useTranslation();

  return (
    <div className="px-4 max-w-screen-lg mx-auto">
      <div className="mx-auto py-4">
        <h1 className="font-bold"> {t("Terms and Conditions")} </h1>
      </div>
      <div className="px-4 pb-6 sm:px-0">
        <p className="mb-3">
          Please read these Terms of Use (“Terms”, “Terms of Use”) carefully
          before using the https://fishingfinity.com website (the “Service,
          App”) operated by ARBEST S.N.C.
        </p>
        <p className="mb-3">
          Your access to and use of the Service/App is conditioned on your
          acceptance of and compliance with these Terms. These Terms apply to
          all visitors, users and others who access or use the Service.
        </p>
        <p className="mb-3">
          By accessing or using the Service/App you agree to be bound by these
          Terms. If you disagree with any part of the terms then you may not
          access the Service.
        </p>
        <h4 className="mb-3 font-bold">Intellectual Property</h4>
        <p className="mb-3">
          The Service and its original content, features and functionality are
          and will remain the exclusive property of Fishingfinity and its
          licensors.
        </p>
        <h4 className="mb-3 font-bold">Links To Other Web Sites</h4>
        <p className="mb-3">
          Our Service may contain links to third-party web sites or services
          that are not owned or controlled by Fishingfinity.
        </p>
        <p className="mb-3">
          Fishingfinity has no control over, and assumes no responsibility for,
          the content, privacy policies, or practices of any third party web
          sites or services. You further acknowledge and agree that
          Fishingfinity shall not be responsible or liable, directly or
          indirectly, for any damage or loss caused or alleged to be caused by
          or in connection with use of or reliance on any such content, goods or
          services available on or through any such web sites or services.
        </p>
        <p className="mb-3">
          We strongly advise you to read the terms and conditions and privacy
          policies of any third-party web sites or services that you visit.
        </p>
        <h4 className="mb-3 font-bold">Termination</h4>
        <p className="mb-3">
          {" "}
          We may terminate or suspend access to our Service immediately, without
          prior notice or liability, for any reason whatsoever, including
          without limitation if you breach the Terms.
        </p>
        <p className="mb-3">
          All provisions of the Terms which by their nature should survive
          termination shall survive termination, including, without limitation,
          ownership provisions, warranty disclaimers, indemnity and limitations
          of liability.
        </p>
        <h4 className="mb-3 font-bold">Disclaimer</h4>
        <p className="mb-3">
          Your use of the Service is at your sole risk. The Service is provided
          on an “AS IS” and “AS AVAILABLE” basis. The Service is provided
          without warranties of any kind, whether express or implied, including,
          but not limited to, implied warranties of merchantability, fitness for
          a particular purpose, non-infringement or course of performance.
        </p>
        <h4 className="mb-3 font-bold">Governing Law</h4>
        <p className="mb-3">
          These Terms shall be governed and construed in accordance with the
          laws of Italy without regard to its conflict of law provisions.
        </p>
        <p className="mb-3">
          Our failure to enforce any right or provision of these Terms will not
          be considered a waiver of those rights. If any provision of these
          Terms is held to be invalid or unenforceable by a court, the remaining
          provisions of these Terms will remain in effect. These Terms
          constitute the entire agreement between us regarding our Service, and
          supersede and replace any prior agreements we might have between us
          regarding the Service.
        </p>
        <h4 className="mb-3 font-bold">Changes</h4>
        <p className="mb-3">
          We reserve the right, at our sole discretion, to modify or replace
          these Terms at any time. If a revision is material we will try to
          provide at least 30 days notice prior to any new terms taking effect.
          What constitutes a material change will be determined at our sole
          discretion.
        </p>
        <p className="mb-3">
          By continuing to access or use our Service after those revisions
          become effective, you agree to be bound by the revised terms. If you
          do not agree to the new terms, please stop using the Service.
        </p>
      </div>
    </div>
  );
}

export default TermsAndConditionScreen;
