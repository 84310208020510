import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CircleLoader } from "react-spinners";

import MyImageList from "./MyImageList";
import { setLoadingImages } from "../../redux/reducers/usersSlice";
import {
  GetPromotionalImagePost,
  LoadMorePromotionalImagePost,
} from "../../redux/actions/users";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

export default function GetUsersPostImage() {
  const { ids, loadingImages, lastPostDoc } = useSelector(
    (store) => store.usersState
  );

  const dispatch = useDispatch();

  const [promotionalPost, setPromotionalPost] = useState([]);

  useEffect(() => {
    function getImages() {
      dispatch(GetPromotionalImagePost());
    }

    getImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (ids.length !== 0) {
      setPromotionalPost(ids);
    }
  }, [ids]);

  const handleLoadingMoreImages = () => {
    if (lastPostDoc !== null) {
      dispatch(setLoadingImages(true));
      dispatch(LoadMorePromotionalImagePost());
    }
  };

  return (
    <div className="gallery-container">
      <h1>Promotional Post Images</h1>
      <div>
        {promotionalPost.length !== 0 ? (
          <MyImageList data={promotionalPost} />
        ) : null}
      </div>
      <CircleLoader
        color={"#0bb005"}
        loading={loadingImages}
        cssOverride={override}
        size={100}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
      {lastPostDoc !== null && !loadingImages && (
        <div className="loadMore-BtnContainer">
          <button onClick={handleLoadingMoreImages}>Load More</button>
        </div>
      )}
    </div>
  );
}
