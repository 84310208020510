import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// import RecordsCharts from "./recordsCharts";
import RecordsList from "./recordsList";
import RecordsFilter from "./recordsFilter";
import { GetRecordsData } from "../../redux/actions/records";

const list = [
  { id: 0, title: "All", value: "all" },
  { id: 1, title: "Mode of Fishing", value: "Mode" },

  { id: 2, title: "Reel Brand", value: "reelBrand" },
  { id: 3, title: "Reel Model", value: "reelModel" },
  { id: 4, title: "Reel Name", value: "reelName" },

  { id: 5, title: "Rod Brand", value: "rodBrand" },
  { id: 6, title: "Rod Model", value: "rodModel" },
  { id: 7, title: "Rod Length", value: "rodLengthM" },
  { id: 8, title: "Thread Brand", value: "threadBrand" },

  { id: 9, title: "Thread Model", value: "threadModel" },
  { id: 10, title: "Thread Diameter", value: "threadDiameter" },
  { id: 11, title: "Species Name", value: "Species" },

  { id: 12, title: "Types of Baits", value: "TypesOfBaits" },
  { id: 13, title: "Lures Brand", value: "luresBrand" },
  { id: 14, title: "Lures Types", value: "luresTypes" },
];

export default function Records() {
  const [filterBy, setFilterBy] = useState({
    id: 0,
    title: "All",
    value: "all",
  });
  const [getRecordsCount, setRecordsCount] = useState({});

  const { recordsFilterData } = useSelector((store) => store.recordState);

  const dispatch = useDispatch();

  useEffect(() => {
    const getRecords = (e) => {
      dispatch(GetRecordsData());
    };
    return () => getRecords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getCounts();
    return () => getCounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordsFilterData]);

  const getCounts = () => {
    if (recordsFilterData) {
      const recordsaObjKeys = Object.keys(recordsFilterData);
      let res = {};
      recordsaObjKeys.map((key) => {
        var obj = recordsFilterData[key].reduce(function (acc, cur) {
          return {
            ...acc,
            [cur.name]: (acc[cur.name] || 0) + 1,
          };
        }, {});

        const ObjKeys = Object.keys(obj);
        const dataObj = ObjKeys.map((objKey) => {
          return {
            name: objKey,
            qty: obj[objKey],
          };
        });
        res = { ...res, [key]: dataObj };
        return res;
      });
      setRecordsCount(res);
    }
  };

  const handleFilter = (e) => {
    e.preventDefault();
    // dispatch(GetRecordsData());
  };

  const handleSelect = (e) => {
    e.preventDefault();
    console.log("handleSelect", e.target.value);
    const findVal = list.find(
      (val) => val.id.toString() === e.target.value.toString()
    );
    setFilterBy(findVal);
  };

  return (
    <div>
      <h1>Catches Record</h1>
      <RecordsFilter
        filterBy={filterBy}
        setFilterBy={setFilterBy}
        handleSelect={handleSelect}
        handleFilter={handleFilter}
        list={list}
      />
      <RecordsList
        getRecordsCount={getRecordsCount}
        setRecordsCount={setRecordsCount}
        handleFilter={handleFilter}
        filterBy={filterBy}
        list={list}
      />
    </div>
  );
}
