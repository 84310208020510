import React, { useState, useCallback } from "react";
import {
  AiFillEyeInvisible,
  AiFillEye,
  AiOutlineInfoCircle,
} from "react-icons/ai";
// import { Link, useNavigate } from "react-router-dom";
// import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { updatePassword, sendPasswordResetEmail } from "firebase/auth";

import { auth } from "../../config/fb";
// import logo from '../images/LOGOfull.png';
// import backgroundTop from "../images/bg03.JPG";
import WelAuth from "./welAuth";

const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const schema = yup
  .object()
  .shape({
    email: yup
      .string()
      .trim()
      .required("Required")
      .email("Invalid email")
      .matches(emailRegex, "Invalid email"),
    password: yup.string().min(6),
  })
  .required();

export default function ResetPassword() {
  const [viewPass, setViewPass] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    pass: "",
    email: "",
  });

  const user = auth.currentUser;
  // const newPassword = getASecureRandomPassword();

  // const navigate = useNavigate();
  // const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data, e) => {
    e.preventDefault();
    const { newPassword } = data;
    try {
      updatePassword(user, newPassword).then(() => {
        sendPasswordResetEmail(auth, user.email).then(() => {
          console.log("Password reset");
        });
      });
    } catch (error) {
      console.error("onsubmit reset password", error);
      const errorCode = error.code;
      switch (errorCode) {
        case "auth/wrong-password":
          setErrorMessage({ ...errorMessage, pass: "incorrect password" });
          break;
        case "auth/user-not-found":
          setErrorMessage({ ...errorMessage, email: "email user not found" });
          break;
        default:
          break;
      }
    }
  };

  const handlePassView = useCallback(() => {
    setViewPass(!viewPass);
  }, [viewPass]);

  return (
    <WelAuth>
      <div className="relative flex flex-col justify-center h-full w-full md:py-20 py-5">
        <form
          onSubmit={handleSubmit(onSubmit)}
          id="password reset form"
          autoComplete="on"
          className="max-w-[500px] w-full mx-auto rounded-lg px-5 py-7 shadow-2xl bg-gray-900"
        >
          <h2 className="font-bold">Reset Password</h2>
          <div className="py-2">
            <label>Email</label>
            <input
              {...register("email", { required: true })}
              id="email"
              type="email"
              autoComplete="on"
              aria-invalid={errors.mail ? "true" : "false"}
              className="input-border form-input"
            />
            {errors.email && (
              <p className="pb-4 text-xs text-red-500 items-center flex pt-1">
                <AiOutlineInfoCircle size={18} className="pr-1" />{" "}
                {errors.email?.message}
              </p>
            )}
            {errorMessage.email && (
              <p className="pb-4 text-xs text-red-500 items-center flex pt-1">
                <AiOutlineInfoCircle size={18} className="pr-1" />{" "}
                {errorMessage.email}
              </p>
            )}
          </div>

          <div className="py-2">
            <label>New Password</label>
            <div className="relative flex">
              <input
                {...register("password", {
                  min: 6,
                  max: 12,
                  required: "Password must be at least 6 characters",
                })}
                id="new password"
                type={viewPass ? "text" : "password"}
                autoComplete="new-password"
                aria-invalid={errors.password ? "true" : "false"}
                className="input-border form-input"
              />
              <button
                onClick={handlePassView}
                className="!absolute right-2 top-1 bottom-1 rounded"
              >
                {viewPass ? (
                  <AiFillEyeInvisible size={20} />
                ) : (
                  <AiFillEye size={20} />
                )}
              </button>
            </div>
          </div>
          <button
            className="uppercase mt-6 py-2 text-xs font-bold rounded-sm border-solid border-2 bg-blue-500 w-full"
            type="submit"
          >
            Reset
          </button>
        </form>
      </div>
    </WelAuth>
  );
}
