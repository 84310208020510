import React, { memo } from "react";
// import { useSelector, useDispatch } from "react-redux";

import RecordsCharts from "./recordsCharts";

function RecordsTable(props) {
  const { title, data } = props;

  // console.log("data", data);

  return (
    data && (
      <div className="recordsTable-container">
        <table className="recordsTable">
          <thead>
            <tr>
              <th>{title}</th>
              <th>No.</th>
            </tr>
          </thead>
          {data.map((record, i) => {
            return (
              <tbody key={i}>
                <tr>
                  <td>{record.name}</td>
                  <td>{record.qty}</td>
                </tr>
              </tbody>
            );
          })}
        </table>
        <RecordsCharts data={data} />
      </div>
    )
  );
}

export default memo(RecordsTable);
