import {
  // createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
// import { revertAll } from "../actions/appActions";
import { GetRecordsData } from "../actions/records";

// export const usersAdapter = createEntityAdapter({
//   selectId: (record) => record.id,
//   sortComparer: (a, b) => b.creation - a.creation,
// });
// XNSlSEtVkgPwK52AV9aezypRQ8s1
const initialState = {
  recordsFilterData: null,
};

export const recordSlice = createSlice({
  name: "recordState",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(GetRecordsData.fulfilled, (state, action) => {
      if (action.payload) {
        state.recordsFilterData = action.payload;
      }
    });
  },
});

export default recordSlice.reducer;
