import React from "react";
import { useSelector } from "react-redux";

import Login from "./auth/login";

export default function ErrorPage() {
  const { currentUser } = useSelector((store) => store.userState);

  return (
    <div className="errorContainer">
      <h1 className="">404 - File or directory not found.</h1>
      <h4>
        The resource you are looking for might have been removed, had its name
        changed, or is temporarily unavailable.
      </h4>
      {!currentUser && <Login />}
    </div>
  );
}
