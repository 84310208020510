import React from "react";

// import logo from "../images/LOGOfull.png";

export default function WelAuth({ children }) {
  return (
    <div className="mainContainer">
      <div className="logo" />
      <div>
        <h1 className="">Admin</h1>
      </div>
      {children}
    </div>
  );
}
