import React, { useEffect, useRef } from "react";

import * as echarts from "echarts";
import { BarChart } from "echarts/charts";

import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
} from "echarts/components";
import { LabelLayout, UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([
  BarChart,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer,
]);

// echarts.use([
//   SVGRenderer,
//   DataZoomComponent,
//   GridComponent,
//   ToolboxComponent,
//   TitleComponent,
//   TooltipComponent,
//   LineChart,
//   LegendComponent,
//   MarkLineComponent,
//   MarkPointComponent,
//   UniversalTransition,
//   CanvasRenderer,
//   DatasetComponent,
//   TransformComponent,
//   BarChart,
//   ScatterChart,
//   PictorialBarChart,
// ]);

let option = {
  xAxis: {
    type: "category",
    data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
  },
  yAxis: {
    type: "value",
  },
  series: [
    {
      data: [120, 200, 150, 80, 70, 110, 130],
      type: "bar",
    },
  ],
};

export default function Chart() {
  let chartRef = useRef();

  useEffect(() => {
    let chart;
    if (chartRef.current) {
      chart = echarts.init(chartRef.current, "light", {
        renderer: "svg",
        width: 500,
        height: 300,
      });
      chart.setOption(option);
    }

    return () => chart?.dispose();
  }, []);

  return (
    <div className="chart-container">
      <div ref={chartRef} />
    </div>
  );
}
