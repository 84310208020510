import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import { collection, getDocs, query, addDoc } from "firebase/firestore";

import { db } from "../config/fb";
import ScrollDialog from "../components/ScrollDialog";

const SendEmail = () => {
  const [contactInfo, setContactInfo] = useState({
    email: [],
    subject: "",
    message: "",
    fullname: "",
  });
  const [addNewEmail, setAddNewEmail] = useState("");
  const [delNewEmail, setADelNewEmail] = useState("");
  const [openMenu, setOpenMenu] = useState(false);

  const { email, message, subject } = contactInfo;
  const { t } = useTranslation();
  const userRef = collection(db, "users");

  const closeModalMenu = () => setOpenMenu(false);

  const htmlDesign = `<!doctype html>
<html>
  <head>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
    <title>Simple Transactional Email</title>
    <style media="all" type="text/css">
@media all {
  .btn-primary table td:hover {
    background-color: #ec0867 !important;
  }

  .btn-primary a:hover {
    background-color: #ec0867 !important;
    border-color: #ec0867 !important;
  }
}
@media only screen and (max-width: 640px) {
  .main p,
.main td,
.main span {
    font-size: 16px !important;
  }

  .wrapper {
    padding: 8px !important;
  }

  .content {
    padding: 0 !important;
  }

  .container {
    padding: 0 !important;
    padding-top: 8px !important;
    width: 100% !important;
  }

  .main {
    border-left-width: 0 !important;
    border-radius: 0 !important;
    border-right-width: 0 !important;
  }

  .btn table {
    max-width: 100% !important;
    width: 100% !important;
  }

  .btn a {
    font-size: 16px !important;
    max-width: 100% !important;
    width: 100% !important;
  }
}
@media all {
  .ExternalClass {
    width: 100%;
  }

  .ExternalClass,
.ExternalClass p,
.ExternalClass span,
.ExternalClass font,
.ExternalClass td,
.ExternalClass div {
    line-height: 100%;
  }

  .apple-link a {
    color: inherit !important;
    font-family: inherit !important;
    font-size: inherit !important;
    font-weight: inherit !important;
    line-height: inherit !important;
    text-decoration: none !important;
  }

  #MessageViewBody a {
    color: inherit;
    text-decoration: none;
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    line-height: inherit;
  }
}
</style>
  </head>
  <body style={{"font-family: Helvetica, sans-serif; -webkit-font-smoothing: antialiased; font-size: 16px; line-height: 1.3; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; background-color: #f4f5f6; margin: 0; padding: 0;">
    <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="body" style={{"border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #f4f5f6; width: 100%;" width="100%" bgcolor="#f4f5f6">
      <tr>
        <td style={{"font-family: Helvetica, sans-serif; font-size: 16px; vertical-align: top;" valign="top">&nbsp;</td>
        <td class="container" style={{"font-family: Helvetica, sans-serif; font-size: 16px; vertical-align: top; max-width: 600px; padding: 0; padding-top: 24px; width: 600px; margin: 0 auto;" width="600" valign="top">
          <div class="content" style={{"box-sizing: border-box; display: block; margin: 0 auto; max-width: 600px; padding: 0;">

            <!-- START CENTERED WHITE CONTAINER -->
            <span class="preheader" style={{"color: transparent; display: none; height: 0; max-height: 0; max-width: 0; opacity: 0; overflow: hidden; mso-hide: all; visibility: hidden; width: 0;">Fishingfinity and Tycoon Lures in Pescare Show 2024.</span>
            <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="main" style={{"border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; background: #ffffff; border: 1px solid #eaebed; border-radius: 16px; width: 100%;" width="100%">

              <!-- START MAIN CONTENT AREA -->
              <tr>
                <td class="wrapper" style={{"font-family: Helvetica, sans-serif; font-size: 16px; vertical-align: top; box-sizing: border-box; padding: 24px;" valign="top">
                   <pre style={{"font-family: Helvetica, sans-serif; font-size: 16px; font-weight: normal; margin: 0; margin-bottom: 16px;">
                   ${message}
                   </pre>
                  <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="btn btn-primary" style={{"border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; box-sizing: border-box; width: 100%; min-width: 100%;" width="100%">
                    <tbody>
                      <tr>
                        <td align="center" style={{"font-family: Helvetica, sans-serif; font-size: 16px; vertical-align: top; padding-bottom: 16px;" valign="top">
                          <table role="presentation" border="0" cellpadding="0" cellspacing="0" style={{"border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: auto;">
                            <tbody>
                              <tr>
                                <td style={{"font-family: Helvetica, sans-serif; font-size: 16px; vertical-align: top; border-radius: 4px; text-align: center; background-color: #0867ec;" valign="top" align="center" bgcolor="#0867ec"> <a href="https://visita.pescareshow.it/reserved-area" target="_blank" style={{"border: solid 2px #0867ec; border-radius: 4px; box-sizing: border-box; cursor: pointer; display: inline-block; font-size: 16px; font-weight: bold; margin: 0; padding: 12px 24px; text-decoration: none; text-transform: capitalize; background-color: #0867ec; border-color: #0867ec; color: #ffffff;">Acquista il biglietto</a> </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>

              <!-- END MAIN CONTENT AREA -->
              </table>

            <!-- START FOOTER -->
            <div class="footer" style={{"clear: both; padding-top: 24px; text-align: center; width: 100%;">
              <table role="presentation" border="0" cellpadding="0" cellspacing="0" style={{"border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%;" width="100%">
                <tr>
                  <td class="content-block" style={{"font-family: Helvetica, sans-serif; vertical-align: top; color: #9a9ea6; font-size: 16px; text-align: center;" valign="top" align="center">
                    <span class="apple-link" style={{"color: #9a9ea6; font-size: 16px; text-align: center;"> Powered by  ARBEST S.N.C</span>
                    <br><a href="https://fishingfinity.com/" style={{"text-decoration: underline; color: #9a9ea6; font-size: 16px; text-align: center;">Unsubscribe</a>.
                  </td>
                </tr>
                <tr>
                  <td class="content-block powered-by" style={{"font-family: Helvetica, sans-serif; vertical-align: top; color: #9a9ea6; font-size: 16px; text-align: center;" valign="top" align="center">
                     <a href="https://fishingfinity.com/" style={{"color: #9a9ea6; font-size: 16px; text-align: center; text-decoration: none;">www.fishingfinity.com</a>
                  </td>
                </tr>
              </table>
            </div>

            <!-- END FOOTER -->
            
<!-- END CENTERED WHITE CONTAINER --></div>
        </td>
        <td style={{"font-family: Helvetica, sans-serif; font-size: 16px; vertical-align: top;" valign="top">&nbsp;</td>
      </tr>
    </table>
  </body>
</html>`;

  const handeleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "Mail"), {
        to: email,
        message: {
          subject,
          html: htmlDesign,
        },
      }).then(() => {
        setContactInfo({
          email: [],
          subject: "",
          message: "",
        });
      });
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const getUsersEmail = async () => {
    try {
      const q = query(userRef);
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((res) => {
        const data = res.data();
        if (
          data.address?.country_name === "Italia" ||
          data.address?.country_name === "Italy"
        ) {
          if (!email.includes(data.email)) {
            let emailList = email;
            emailList.unshift(data.email);
            setContactInfo({ ...contactInfo, email: emailList });
          }
        }
      });
    } catch (error) {
      console.error("AddAdmin error: " + error);
    }
  };

  const handleAddEmail = (e) => {
    e.preventDefault();
    if (!email.includes(addNewEmail)) {
      try {
        let emailList = email;
        emailList.unshift(addNewEmail);
        setContactInfo({ ...contactInfo, email: emailList });
      } catch (error) {
        console.error("handleAddEmail: " + error);
      } finally {
        setAddNewEmail("");
      }
    } else {
      alert(`${addNewEmail} is already in the list`);
    }
  };

  const handleRemoveEmail = (e) => {
    e.preventDefault();
    try {
      let emailList = email;
      emailList = emailList.filter((item) => {
        return item !== delNewEmail;
      });
      setContactInfo({ ...contactInfo, email: emailList });
    } catch (error) {
      console.error("handleAddEmail: " + error);
    } finally {
      setADelNewEmail("");
    }
  };

  return (
    <div
      className="center-container"
      onClick={() => (openMenu ? setOpenMenu(false) : null)}
    >
      <h1>{"Send Email"}</h1>
      <div className="flexCont">
        <p>Total Emails: {email.length}</p>
        <Button onClick={() => setOpenMenu(true)}>View List of Emails</Button>
        <Button onClick={getUsersEmail}>Get List of Emails</Button>
      </div>

      <form action="#" className="auth-inner" id="send email form">
        <div className="inputContainer">
          <label htmlFor="addEmail" className="">
            {t("Add Email to List")}
          </label>
          <input
            id="addEmail"
            type="email"
            value={addNewEmail}
            onChange={({ target }) => setAddNewEmail(target.value)}
            className="inputBox"
            placeholder="name@example.com"
          />
          <button
            onClick={handleAddEmail}
            type="submit"
            style={{
              backgroundColor: "#4285F4",
              marginTop: "10px",
              alignItems: "center",
              justifyContent: "center",
              width: "50%",
              padding: "5px",
            }}
          >
            {t("Add Email")}
          </button>
        </div>

        <div className="inputContainer">
          <label htmlFor="delEmail" className="">
            {t("Remove Email from List")}
          </label>
          <input
            id="delEmail"
            type="email"
            value={delNewEmail}
            onChange={({ target }) => setADelNewEmail(target.value)}
            className="inputBox"
            placeholder="name@example.com"
          />
          <button
            onClick={handleRemoveEmail}
            type="submit"
            style={{
              backgroundColor: "#4285F4",
              marginTop: "10px",
              alignItems: "center",
              justifyContent: "center",
              width: "50%",
              padding: "5px",
            }}
          >
            {t("Remove Email")}
          </button>
        </div>

        <div className="inputContainer">
          <label htmlFor="subject" className="">
            {t("Subject")}
          </label>
          <input
            type="text"
            id="subject"
            value={subject}
            onChange={({ target }) =>
              setContactInfo({ ...contactInfo, subject: target.value })
            }
            className="inputBox"
            placeholder={"Subject"}
            required
          />
        </div>

        <div className="inputContainer">
          <label htmlFor="message" className="">
            Your message
          </label>
          <textarea
            id="message"
            value={message}
            rows="6"
            onChange={({ target }) =>
              setContactInfo({ ...contactInfo, message: target.value })
            }
            placeholder={t("Leave a comment") + "..."}
            required
          />
        </div>

        <button
          onClick={handeleSubmit}
          type="submit"
          className="loginBtn"
          style={{ backgroundColor: "#4285F4", marginTop: "30px" }}
        >
          {t("Send message")}
        </button>
      </form>

      <ScrollDialog
        title={"List of Emails"}
        type="paper"
        list={email}
        openMenu={openMenu}
        closeModalMenu={closeModalMenu}
      >
        {email.length !== 0 ? (
          email.map((item) => {
            return (
              <ul key={item}>
                <li>{item}</li>
              </ul>
            );
          })
        ) : (
          <p>You have 0 list</p>
        )}
      </ScrollDialog>
    </div>
  );
};

export default SendEmail;
