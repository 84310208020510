import React from "react";
// import { useNavigate } from "react-router-dom";
// import { signOut } from "firebase/auth";
// import { useDispatch } from "react-redux";
import { FaImages } from "react-icons/fa";
import { RiAdminFill, RiMailSendFill, RiDashboard3Fill } from "react-icons/ri";
import { GiFishing } from "react-icons/gi";
import { BsQrCode } from "react-icons/bs";
// import { ImExit } from "react-icons/im";

// import { auth } from "../config/fb";
// import { CURRENT_USER } from "../redux/reducers/userSlice";

const sideTabsList = [
  {
    id: "01",
    link: "/",
    title: "Dashboard",
    icon: <RiDashboard3Fill size={20} />,
  },
  {
    id: "02",
    link: "/Admin",
    title: "Add/Remove Admin",
    icon: <RiAdminFill size={20} />,
  },
  {
    id: "03",
    link: "/sendEmailToUsers",
    title: "Send Email",
    icon: <RiMailSendFill size={20} />,
  },
  {
    id: "04",
    link: "/userPosts",
    title: "Get Post Images",
    icon: <FaImages size={20} />,
  },
  {
    id: "05",
    link: "/Records",
    title: "Records",
    icon: <GiFishing size={20} />,
  },
  {
    id: "06",
    link: "/tycoonLures",
    title: "TycoonLures QR Code",
    icon: <BsQrCode size={20} />,
  },
];

export default function SideNavBar({ open }) {
  // const dispatch = useDispatch();
  // const navigate = useNavigate();

  // const handleLogout = () => {
  //   try {
  //     signOut(auth).then(() => {
  //       navigate("/");
  //       dispatch(CURRENT_USER(null));
  //     });
  //   } catch (error) {
  //     console.error("handleLogout", error);
  //   }
  // };

  return (
    <div
      className="sidebar"
      style={{
        width: open ? 250 : 70,
      }}
    >
      {sideTabsList.map((data, idx) => {
        return (
          <a href={data.link} key={data.id}>
            {data.icon}
            <p> {open && data.title}</p>
          </a>
        );
      })}
      {/* <a onClick={handleLogout}>
        <ImExit size={20} />
        <p> {open && "Sign Out"}</p>
      </a> */}
    </div>
  );
}
