import React, { useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { useSelector, useDispatch } from "react-redux";
import { RouterProvider } from "react-router-dom";

import { MainRouter, AuthRouter } from "./Routers";
import { CURRENT_USER, USER } from "./redux/reducers/userSlice";
import { auth } from "./config/fb";

function App() {
  const { currentUser } = useSelector((store) => store.userState);
  const [initializing, setInitializing] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      try {
        if (user) {
          user
            .getIdTokenResult()
            .then((idTokenResult) => {
              if (idTokenResult.claims?.admin) {
                dispatch(CURRENT_USER(user));
              }
            })
            .catch((error) => {
              console.log(error);
            });
          if (initializing) {
            setInitializing(false);
          }
        } else {
          dispatch(CURRENT_USER(null));
          dispatch(USER(null));
          setInitializing(false);
        }
      } catch (error) {
        console.error("onAuthStateChanged", error);
        dispatch(CURRENT_USER(null));
        dispatch(USER(null));
        setInitializing(false);
      }
    });
    return () => unsubscribe();
  }, [dispatch, currentUser, initializing]);

  if (initializing) {
    return;
  }

  return <RouterProvider router={currentUser ? MainRouter : AuthRouter} />;
}
export default App;
