import React, { useState } from "react";
import { Outlet } from "react-router-dom";

import MainAppBar from "./MainAppBar";
import SideNavBar from "./NavBar/sideNavBar";

export default function Root() {
  const [open, setOpen] = useState(true);

  const handleDrawerMenuBtn = () => {
    setOpen(!open);
  };

  return (
    <React.Fragment>
      <MainAppBar open={open} handleDrawerOpen={handleDrawerMenuBtn} />
      <div style={{ marginLeft: open ? 250 : 70 }}>
        <SideNavBar open={open} />
        <main>
          <Outlet />
        </main>
      </div>
    </React.Fragment>
  );
}
