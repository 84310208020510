import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import userReducer from "./reducers/userSlice";
import usersReducer from "./reducers/usersSlice";
import recordReducer from "./reducers/recordSlice";

export const store = configureStore({
  reducer: {
    userState: userReducer,
    usersState: usersReducer,
    recordState: recordReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

setupListeners(store.dispatch);
