import React from "react";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

import PrivacyAndPolicyScreen from "./Pages/PrivacyAndPolicy";
import TermsAndConditionScreen from "./Pages/TermsAndCondition";
import ErrorPage from "./Pages/ErrorPage";
import Root from "./Pages/Root";
import SignUp from "./Pages/auth/signUp";
import Login from "./Pages/auth/login";
// import Main from "./Pages/Main";
import ResetPassword from "./Pages/auth/resetPassword";
import AuthRoot from "./Pages/auth/authRoot";
import AddAdmin from "./Pages/Admin/AddAdmin";
import DeleteAdmin from "./Pages/Admin/DeleteAdmin";
import GetUsersPostImage from "./Pages/UsersInfo/GetUsersPostImage";
import SendEmail from "./Pages/SendEmail";
import Admin from "./Pages/Admin";
import Dashboard from "./Pages/Dashboard";
import Records from "./Pages/Records";
import TycoonQrCodeScreen from "./Pages/TycoonCode";

export const MainRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Root />}>
      <Route index element={<Dashboard />} />
      <Route path="userPosts" element={<GetUsersPostImage />} />
      <Route path="sendEmailToUsers" element={<SendEmail />} />
      <Route path="privacyAndPolicy" element={<PrivacyAndPolicyScreen />} />
      <Route path="termsAndConditions" element={<TermsAndConditionScreen />} />
      <Route path="addAminUser" element={<AddAdmin />} />
      <Route path="delAdminUser" element={<DeleteAdmin />} />
      <Route path="Admin" element={<Admin />} />
      <Route path="Records" element={<Records />} />
      <Route path="tycoonLures" element={<TycoonQrCodeScreen />} />
      <Route path="*" element={<ErrorPage />} />
    </Route>
  )
);

export const AuthRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<AuthRoot />}>
      <Route index element={<Login />} />
      <Route path="signup" element={<SignUp />} />
      <Route path="login" element={<Login />} />
      <Route path="resetpassword" element={<ResetPassword />} />
      <Route path="*" element={<ErrorPage />} />
    </Route>
  )
);
