import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getStorage } from "firebase/storage";
// import { getMessaging } from "firebase/messaging";
import { getFunctions, httpsCallable } from "firebase/functions";

// import serviceAccount from "./serviceAccount.json";

const provider = new GoogleAuthProvider();

const firebaseConfig = {
  apiKey: "AIzaSyB8ga7XUr6GV-3pnMtdZJ8G44FRdG0LJ78",
  authDomain: "fishingfinity-bytycoonlures.firebaseapp.com",
  databaseURL:
    "https://fishingfinity-bytycoonlures-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "fishingfinity-bytycoonlures",
  storageBucket: "fishingfinity-bytycoonlures.appspot.com",
  messagingSenderId: "736243809654",
  appId: "1:736243809654:web:40d37a9aaa480296c20e57",
  measurementId: "G-2GZQQ72T4K",
  regionOrCustomDomain: "europe-west1",
};

// admin.initializeApp({
//   credential: fs.credential.cert(serviceAccount),
// });

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);
const analytics = getAnalytics(app);
const functions = getFunctions(app, "europe-west1");
// const messaging = getMessaging(app);

export {
  db,
  auth,
  storage,
  functions,
  httpsCallable,
  // messaging,
  provider,
  analytics,
  logEvent,
  // admin,
};
