import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function PrivacyAndPolicyScreen() {
  const { t } = useTranslation();

  return (
    <div className="px-4 max-w-screen-lg mx-auto">
      <div className="mx-auto py-4">
        <h1 className="font-bold">{t("Privacy and Policy")}</h1>
      </div>

      <div className="px-4 pb-6 sm:px-0">
        <p className="mb-3">
          ARBEST S.N.C operates the Fishingfinity and website.
        </p>
        <p className="mb-3">
          This page informs you of our policies regarding the collection, use
          and disclosure of Personal Information when you use our Service.
        </p>
        <p className="mb-3">
          We will not use or share your information with anyone except as
          described in this Privacy Policy.
        </p>
        <p className="mb-3">
          We use your Personal Information for providing and improving the
          Service. By using the Service, you agree to the collection and use of
          information in accordance with this policy. Unless otherwise defined
          in this Privacy Policy, terms used in this Privacy Policy have the
          same meanings as in our Terms and Conditions, accessible at
          <Link to="/" className="hover:text-blue-500 hover:underline pl-1">
            https://fishingfinity.com{" "}
          </Link>
        </p>

        <h4 className="mb-3 font-bold">Information Collection And Use</h4>
        <p>
          While using our Service, we may ask you to provide us with certain
          personally identifiable information that can be used to contact or
          identify you. Personally identifiable information (“Personal
          Information”) may include, but is not limited to:
        </p>
        <div className="m-5 text-gray-500">
          <li>Name</li>
          <li>Email address</li>
          <li>Birthdate</li>
          <li>Gender</li>
          <li>Location</li>
          <li>Language</li>
          <li>Interest</li>
        </div>
        <h4 className="mb-3 font-bold">Log Data</h4>
        <p className="mb-3">
          We collect information that your browser sends whenever you visit our
          Service (“Log Data”). This Log Data may include information such as
          your computer’s Internet Protocol (“IP”) address, browser type,
          browser version, the pages of our Service that you visit, the time and
          date of your visit, the time spent on those pages and other
          statistics.
        </p>
        <h4 className="mb-3 font-bold">Google AdSense & DoubleClick Cookie</h4>
        <p className="mb-3">
          Google, as a third party vendor, uses cookies to serve ads on our
          Service.
        </p>
        <h4 className="mb-3 font-bold">Cookies</h4>
        <p className="mb-3">
          Cookies are files with small amount of data, which may include an
          anonymous unique identifier. Cookies are sent to your browser from a
          web site and stored on your computer’s hard drive.
        </p>
        <p className="mb-3">
          We use “cookies” to collect information. You can instruct your browser
          to refuse all cookies or to indicate when a cookie is being sent.
          However, if you do not accept cookies, you may not be able to use some
          portions of our Service.
        </p>
        <h4 className="mb-3 font-bold">Service Providers</h4>
        <p className="mb-3">
          We may employ third party companies and individuals to facilitate our
          Service, to provide the Service on our behalf, to perform
          Service-related services or to assist us in analyzing how our Service
          is used.
        </p>
        <p className="mb-3">
          These third parties have access to your Personal Information only to
          perform these tasks on our behalf and are obligated not to disclose or
          use it for any other purpose.
        </p>
        <h4 className="mb-3 font-bold">Security</h4>
        <p className="mb-3">
          The security of your Personal Information is important to us, but
          remember that no method of transmission over the Internet, or method
          of electronic storage is 100% secure. While we strive to use
          commercially acceptable means to protect your Personal Information, we
          cannot guarantee its absolute security.
        </p>
        <h4 className="mb-3 font-bold">Links To Other Sites</h4>
        <p className="mb-3">
          Our Service may contain links to other sites that are not operated by
          us. If you click on a third party link, you will be directed to that
          third party’s site. We strongly advise you to review the Privacy
          Policy of every site you visit.
        </p>
        <p className="mb-3">
          We have no control over, and assume no responsibility for the content,
          privacy policies or practices of any third party sites or services.
        </p>
        <h4 className="mb-3 font-bold">Children’s Privacy</h4>
        <p className="mb-3">
          Our Service does not address anyone under the age of 18 (“Children”).
        </p>
        <p className="mb-3">
          We do not knowingly collect personally identifiable information from
          children under 18. If you are a parent or guardian and you are aware
          that your child has provided us with Personal Information, please
          contact us. If we discover that a child under 18 has provided us with
          Personal Information, we will delete such information from our servers
          immediately.
        </p>
        <h4 className="mb-3 font-bold">Compliance With Laws</h4>
        <p className="mb-3">
          We will disclose your Personal Information where required to do so by
          law or subpoena.
        </p>
        <h4 className="mb-3 font-bold">Changes To This Privacy Policy</h4>
        <p className="mb-3">
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page.
        </p>
        <p className="mb-3">
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </p>
        <h4 className="mb-3 font-bold">Contacting Fishingfinity</h4>
        <p className="mb-3">
          If you have any questions about this privacy policy, please contact us
          <Link to="/support" className="text-blue-500 hover:underline">
            {" "}
            here.{" "}
          </Link>
        </p>
      </div>
    </div>
  );
}

export default PrivacyAndPolicyScreen;
