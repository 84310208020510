import * as React from "react";
import { IoMdMenu } from "react-icons/io";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { ImExit } from "react-icons/im";

import { auth } from "../config/fb";
import { CURRENT_USER } from "../redux/reducers/userSlice";

function MainAppBar({ handleDrawerOpen }) {
  const { currentUser } = useSelector((store) => store.userState);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      signOut(auth).then(() => {
        navigate("/");
        dispatch(CURRENT_USER(null));
      });
    } catch (error) {
      console.error("handleLogout", error);
    }
  };

  return (
    <div className="AppBar">
      <div className="AppBar-left">
        <div onClick={handleDrawerOpen}>
          <IoMdMenu size={25} />
        </div>
        <div className="logo" alt="Logo" />
      </div>
      <div className="profile-container">
        <img src={currentUser.photoURL} alt="profile user" />
        <p>{currentUser.displayName}</p>
        <div onClick={handleLogout}>
          <ImExit size={20} />
          <p> {"Sign Out"}</p>
        </div>
      </div>
    </div>
  );
}

export default React.memo(MainAppBar);
