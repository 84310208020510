import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./Translation/i18n";
import { store } from "./redux/store";

import "./styles/App.css";
import "./styles/index.css";
import "./styles/users.css";
import "./styles/navBar.css";
import "./styles/dashboard.css";
import "./styles/records.css";
import "./styles/qrCode.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
