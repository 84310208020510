import React, {
  memo,
  // useEffect, useState
} from "react";
// import { useSelector, useDispatch } from "react-redux";

// import { GetRecordsData } from "../../redux/actions/records";

function RecordsFilter(props) {
  const {
    // filterBy,
    // setFilterBy,
    handleFilter,
    handleSelect,
    list,
  } = props;

  return (
    <div className="record-filter-container">
      <select defaultValue="Select:" onChange={handleSelect}>
        {list.map((val, i) => (
          <option key={i} value={val.id}>
            {val.title}
          </option>
        ))}
      </select>
      {/* <select defaultValue="Select:" onChange={handleSelect}>
        {list.map((val, i) => (
          <option key={i} value={val.id}>
            {val.title}
          </option>
        ))}
      </select> */}
      <button type="submit" onClick={handleFilter}>
        Filter
      </button>
    </div>
  );
}

export default memo(RecordsFilter);
