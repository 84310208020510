import React from "react";

export default function RecordsCharts(props) {
  // const { data } = props;
  return (
    <div className="record-chart-container">
      recordsCharts
      <div></div>
    </div>
  );
}
