import React from "react";
// import { useSelector, useDispatch } from "react-redux";

import Chart from "./Chart";
// import { analytics, logEvent, auth } from "../../config/fb";

export default function Dashboard() {
  // console.log(analytics, "select_content", {
  //   content_type: "image",
  //   content_id: "P12453",
  // });
  return (
    <div className="dash-main-cont">
      <h1>Dashboard</h1>
      <Chart />
    </div>
  );
}
