import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  collection,
  getDocs,
  query,
  // where,
  orderBy,
  // limit,
  // startAfter,
  // arrayUnion,
  // setDoc,
  // updateDoc,
  // doc,
  // getDoc,
} from "firebase/firestore";

import { db } from "../../config/fb";

// let userRef = collection(db, "users");
let recordRef = collection(db, "Records");

//XNSlSEtVkgPwK52AV9aezypRQ8s1

export const GetRecordsData = createAsyncThunk(
  "records/getAllData",
  async (_, { dispatch, getState }) => {
    let q = query(recordRef, orderBy("RodModel"));
    const removeEmptyValues = (array) => {
      //   const filtered = array.filter((e) => e);
      const filtered = array.filter(Boolean);
      let values = filtered;
      if (filtered.length > 0) {
        values = filtered.map((item) => item.trim());
      }
      var obj = values.reduce(function (acc, cur, i) {
        return cur;
      }, {});

      if (Object.keys(obj).length === 0) {
        return;
      }
      return { name: obj };
    };

    try {
      const recordSnapshot = await getDocs(q);
      const records = {
        luresBrand: [],
        luresTypes: [],
        reelBrand: [],
        reelModel: [],
        reelName: [],
        rodBrand: [],
        rodModel: [],
        rodLengthM: [],
        threadBrand: [],
        threadModel: [],
        threadDiameter: [],
        Mode: [],
        Species: [],
        TypesOfBaits: [],
      };

      recordSnapshot.docs.map((rec) => {
        // const id = rec.id;
        const data = rec.data();
        const {
          LuresBrand,
          LuresTypes,
          ReelBrand,
          ReelModel,
          ReelName,
          RodBrand,
          RodLengthM,
          RodModel,
          ThreadBrand,
          ThreadDiameter,
          ThreadModel,
          mode,
          species,
          typesOfBaits,
          // geohash,
          // region,
        } = data;
        const mergeRecords = (title, str) => {
          if (removeEmptyValues(str)) {
            records[title].push(removeEmptyValues(str));
          }
        };
        mergeRecords("luresBrand", LuresBrand);
        mergeRecords("luresTypes", LuresTypes);
        mergeRecords("reelBrand", ReelBrand);
        mergeRecords("reelModel", ReelModel);
        mergeRecords("reelName", ReelName);
        mergeRecords("rodBrand", RodBrand);
        mergeRecords("rodModel", RodModel);
        mergeRecords("rodLengthM", RodLengthM);
        mergeRecords("threadBrand", ThreadBrand);
        mergeRecords("threadModel", ThreadModel);
        mergeRecords("threadDiameter", ThreadDiameter);
        mergeRecords("Mode", mode);
        mergeRecords("Species", species);
        mergeRecords("TypesOfBaits", typesOfBaits);
        // const Geohash = {
        //   geohash: removeEmptyValues(geohash),
        // };
        // const Region = {
        //   region: removeEmptyValues(region),
        // };

        // records.push({
        //   id,
        //   // Lures,
        //   lureBrand,
        //   luresTypes,
        //   Reel,
        //   Rod,
        //   Thread,
        //   Mode,
        //   Species,
        //   TypesOfBaits,
        //   // Geohash,
        //   // region,
        // });
        return mergeRecords();
      });
      return { ...records };
    } catch (error) {
      console.error("GetRecordsData", error);
    }
  }
);

// export const UpdateRecordsData = createAsyncThunk(
//   "records/getAllData",
//   async (_, { dispatch, getState }) => {
//     let q = query(userRef, where("catchCount", "!=", 0));
//     try {
//       const userSnapshot = await getDocs(q);
//       userSnapshot.docs.map(async (snapUser) => {
//         const id = snapUser.id;
//         const recordSnapshot = await getDocs(
//           collection(db, "Records", id, "userRecords")
//         );
//         if (!recordSnapshot.empty) {
//           recordSnapshot.docs.map(async (snap) => {
//             const {
//               Lures,
//               Reel,
//               Rod,
//               Thread,
//               Mode,
//               TypeOfBaits,
//               Species,
//               geohash,
//               Region,
//             } = snap.data();
//             const { ReelBrand, ReelModel, ReelName } = Reel;
//             const { LuresBrand, TypeOfLures } = Lures;
//             const { RodBrand, RodLengthM, RodModel } = Rod;
//             const { ThreadBrand, ThreadDiameter, ThreadModel } = Thread;

//             await updateDoc(doc(recordRef, id), {
//               LuresBrand: arrayUnion(
//                 LuresBrand !== null && LuresBrand.length !== 0
//                   ? LuresBrand.toLowerCase()
//                   : null
//               ),
//               LuresTypes: arrayUnion(
//                 TypeOfLures !== null && TypeOfLures.length !== 0
//                   ? TypeOfLures.toLowerCase()
//                   : null
//               ),
//               ReelBrand: arrayUnion(
//                 ReelBrand !== null && ReelBrand.length !== 0
//                   ? ReelBrand.toLowerCase()
//                   : null
//               ),
//               ReelModel: arrayUnion(
//                 ReelModel !== null && ReelModel.length !== 0
//                   ? ReelModel.toLowerCase()
//                   : null
//               ),
//               ReelName: arrayUnion(
//                 ReelName !== null && ReelName.length !== 0
//                   ? ReelName.toLowerCase()
//                   : null
//               ),
//               RodBrand: arrayUnion(
//                 RodBrand !== null && RodBrand.length !== 0
//                   ? RodBrand.toLowerCase()
//                   : null
//               ),
//               RodLengthM: arrayUnion(
//                 RodLengthM !== null && RodLengthM.length !== 0
//                   ? RodLengthM.toLowerCase()
//                   : null
//               ),
//               RodModel: arrayUnion(
//                 RodModel !== null && RodModel.length !== 0
//                   ? RodModel.toLowerCase()
//                   : null
//               ),
//               ThreadBrand: arrayUnion(
//                 ThreadBrand !== null && ThreadBrand.length !== 0
//                   ? ThreadBrand.toLowerCase()
//                   : null
//               ),
//               ThreadDiameter: arrayUnion(
//                 ThreadDiameter !== null && ThreadDiameter.length !== 0
//                   ? ThreadDiameter.toLowerCase()
//                   : null
//               ),
//               ThreadModel: arrayUnion(
//                 ThreadModel !== null && ThreadModel.length !== 0
//                   ? ThreadModel.toLowerCase()
//                   : null
//               ),
//               typesOfBaits: arrayUnion(
//                 TypeOfBaits !== null && TypeOfBaits.length !== 0
//                   ? TypeOfBaits.toLowerCase()
//                   : null
//               ),
//               species: arrayUnion(
//                 Species !== null && Species.length !== 0
//                   ? Species.toLowerCase()
//                   : null
//               ),
//               mode: arrayUnion(
//                 Mode !== null && Mode.length !== 0 ? Mode.toLowerCase() : null
//               ),
//               geohash: arrayUnion(geohash ? geohash : null),
//               region: arrayUnion({
//                 ...Region,
//                 geohash: geohash ? geohash : null,
//               }),
//             });
//           });
//         }
//       });
//     } catch (error) {
//       console.error("GetRecordsData", error);
//     }
//   }
// );
