import React from "react";
import AddAdmin from "./AddAdmin";
import DeleteAdmin from "./DeleteAdmin";

export default function index() {
  return (
    <div className="center-container text-center">
      <AddAdmin />
      <DeleteAdmin />
    </div>
  );
}
