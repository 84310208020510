import React from "react";
import { QRCode } from "react-qrcode-logo";
import { useTranslation } from "react-i18next";

import tycoonLogo from "../images/tycoonLogo.jpg";

export default function TycoonQrCodeScreen() {
  const { t } = useTranslation();

  const linkList = [
    {
      id: 0,
      name: "Pisellin Glow 3.5cm",
      link: "https://www.tycoonlures.it/pisellino_glow3.5",
    },
    {
      id: 1,
      name: "Pisellin Glow 5cm",
      link: "https://www.tycoonlures.it/pisellino_glow5",
    },
    {
      id: 2,
      name: "Pisellin Glow 7.5cm",
      link: "https://www.tycoonlures.it/pisellino_glow7.5",
    },
    {
      id: 3,
      name: "Pisellin Glow 9.5cm",
      link: "https://www.tycoonlures.it/PISELLINO_9.5",
    },
    {
      id: 4,
      name: "PISELLINO12",
      link: "https://www.tycoonlures.it/pisellino12",
    },
    {
      id: 5,
      name: "PISELLINO15",
      link: "https://www.tycoonlures.it/pisellino15",
    },
    {
      id: 6,
      name: "PISELLINO23",
      link: "https://www.tycoonlures.it/PISELLINO23",
    },
    {
      id: 7,
      name: "Anguillina ceca",
      link: "https://www.tycoonlures.it/anguillina_ceca",
    },
    {
      id: 8,
      name: "snake worm",
      link: "https://www.tycoonlures.it/Verme_Serpente_Snake_Worm",
    },
    {
      id: 9,
      name: "KNG-5 SHAD",
      link: "https://www.tycoonlures.it/KNG-5",
    },
    {
      id: 10,
      name: "STRAWBERRY STRAWGUM",
      link: "https://www.tycoonlures.it/STRAWGUM",
    },
    {
      id: 11,
      name: "MORA BLACKGUM",
      link: "https://www.tycoonlures.it/BLACKGUM",
    },
    {
      id: 12,
      name: "Kasura Jig",
      link: "https://www.tycoonlures.it/Kasura_Jig",
    },
    {
      id: 13,
      name: "Simple Holder special FIVE BAITS",
      link: "https://www.tycoonlures.it/Simple_Holder",
    },
    {
      id: 14,
      name: "Motor Holder Combo",
      link: "https://www.tycoonlures.it/Holder_lures",
    },
    {
      id: 15,
      name: "Resin Mixer",
      link: "https://www.tycoonlures.it/RESINMIXER",
    },
    {
      id: 16,
      name: "Resinator Regulator",
      link: "https://www.tycoonlures.it/Resinator_Regulator",
    },
    {
      id: 17,
      name: "Buono Regalo",
      link: "https://www.tycoonlures.it/buono_regalo",
    },
    {
      id: 18,
      name: "Micro Holder",
      link: "https://www.tycoonlures.it/Micro_Holder",
    },
    {
      id: 19,
      name: "Stampi Personalizzati",
      link: "https://www.tycoonlures.it/Stampi_personalizzati",
    },
    {
      id: 20,
      name: "Metro Misura Pesci Measure Fish",
      link: "https://www.tycoonlures.it/Metro_Misura_Pesci",
    },
    {
      id: 21,
      name: "Tube UV",
      link: "https://www.tycoonlures.it/Tube_UV",
    },
    {
      id: 22,
      name: "Tycoon Lures Website",
      link: "https://www.tycoonlures.com",
    },
  ];

  const downloadCode = (name) => {
    const canvas = document.getElementById(name);
    if (canvas) {
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      let downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = `${name}.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  return (
    <div className="qr-code-container">
      <h1>Tycoon Lures Products (QR Code)</h1>
      <div className="qr-box">
        {linkList.map(({ id, link, name }) => {
          return (
            <div key={id} className="qr-box-item">
              <div>
                <p>{name}</p>
                <a href={link.toString()} target="_blank" rel="noreferrer">
                  View Website
                </a>
                <QRCode
                  value={link}
                  size={500}
                  logoImage={tycoonLogo}
                  logoHeight={60}
                  logoWidth={200}
                  logoOpacity={1}
                  ecLevel="Q"
                  removeQrCodeBehindLogo={true}
                  logoPadding={10}
                  logoPaddingStyle="circle"
                  enableCORS={true} // enabling CORS, this is the thing that will bypass that DOM check
                  qrStyle="dots" // type of qr code, wether you want dotted ones or the square ones
                  eyeRadius={[
                    [50, 50, 0, 50],
                    [50, 50, 50, 0],
                    [50, 0, 50, 50],
                  ]}
                  eyeColor={"#0830fc"}
                  fgColor={"#0830fc"}
                  quietZone={20}
                  id={name}
                />
              </div>

              <button type="button" onClick={() => downloadCode(name)}>
                {t("Download QR Code")}
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
}
