import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  collection,
  getDocs,
  query,
  where,
  orderBy,
  limit,
  startAfter,
  // doc,
  // getDoc,
} from "firebase/firestore";

import { db } from "../../config/fb";
import { USERS_LAST_POST } from "../reducers/usersSlice";

const limitquery = 20;
let userRef = collection(db, "users");
// let mktgRef = collection(db, "Marketing");

export const GetPromotionalImagePost = createAsyncThunk(
  "users/getImage",
  async (_, { dispatch, getState }) => {
    let q = query(
      userRef,
      where("lastPost", "!=", null),
      orderBy("lastPost", "desc"),
      limit(limitquery)
    );

    try {
      const querySnapshot = await getDocs(q);
      const last = querySnapshot.docs[querySnapshot.docs.length - 1];
      dispatch(USERS_LAST_POST(last));

      if (querySnapshot.docs.length < limitquery) {
        dispatch(USERS_LAST_POST(null));
      }

      if (!querySnapshot.empty) {
        let usersData = [];
        const usersLastPost = querySnapshot.docs.map((doc) => {
          const docData = doc.data();
          return { ...docData };
        });

        console.log("usersLastPost", usersLastPost.length);

        for (let i = 0; i < usersLastPost.length; i++) {
          const snapShotMktg = await getDocs(
            collection(db, "Marketing", usersLastPost[i].uid, "urls")
          );
          snapShotMktg.docs.map((doc) => {
            const id = doc.id;
            const data = doc.data();
            return usersData.push({ ...data, id, user: usersLastPost[i] });
          });
        }
        return usersData;
      } else {
        return [];
      }
    } catch (error) {
      console.error("getImage error: " + error);
    }
  }
);

export const LoadMorePromotionalImagePost = createAsyncThunk(
  "users/getMoreImage",
  async (_, { dispatch, getState }) => {
    const { lastPostDoc } = await getState().usersState;

    if (lastPostDoc !== null) {
      let q = query(
        userRef,
        where("lastPost", "!=", null),
        orderBy("lastPost", "desc"),
        startAfter(lastPostDoc),
        limit(limitquery)
      );

      try {
        const querySnapshot = await getDocs(q);
        const last = querySnapshot.docs[querySnapshot.docs.length - 1];
        dispatch(USERS_LAST_POST(last));

        if (querySnapshot.docs.length < limitquery) {
          dispatch(USERS_LAST_POST(null));
        }

        if (!querySnapshot.empty) {
          let usersData = [];
          const usersLastPost = querySnapshot.docs.map((doc) => {
            const docData = doc.data();
            return { ...docData };
          });

          for (let i = 0; i < usersLastPost.length; i++) {
            const snapShotMktg = await getDocs(
              collection(db, "Marketing", usersLastPost[i].uid, "urls")
            );

            snapShotMktg.docs.map((doc) => {
              const id = doc.id;
              const data = doc.data();
              return usersData.push({ ...data, id, user: usersLastPost[i] });
            });
          }
          return usersData;
        } else {
          return [];
        }
      } catch (error) {
        console.error("getImage error: " + error);
      }
    }
  }
);
