import React from "react";
import { useSelector } from "react-redux";
import { TbCloudDownload } from "react-icons/tb";

export default function MyImageList(props) {
  const { data } = props;

  const { entities } = useSelector((store) => store.usersState);

  const handleDownloadImage = async (imgUrl, id) => {
    const imageblob = await fetch(imgUrl)
      .then((response) => response.arrayBuffer())
      .then((buffer) => new Blob([buffer], { type: "image/jpeg" }));
    const link = document.createElement("a");
    link.href = URL.createObjectURL(imageblob);
    link.style.display = "none";
    link.download = id;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // const openInNewTab = (url) => {
  //   const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  //   if (newWindow) newWindow.opener = null;
  // };

  return (
    data.length !== 0 &&
    data.map((id, idx) => {
      const item = entities[id];
      return (
        <div key={id} className="responsive">
          <div className="gallery">
            <a target="_blank" rel="noreferrer" href={item?.url[0]}>
              <img
                src={item?.url[0]}
                alt="user post"
                // onClick={() => openInNewTab(item?.url[0])}
              />
            </a>
            <div className="desc">{item.user?.displayName} </div>
            <div
              className="download-btnContainer"
              onClick={() => handleDownloadImage(item?.url[0], item.id)}
            >
              <TbCloudDownload color="green" size={30} />
            </div>
          </div>
        </div>
      );
    })
  );

  // return (
  //   <ImageList cols={3}>
  //     {data.length !== 0 &&
  //       data.map((id) => {
  //         const item = entities[id];
  //         return (
  //           <ImageListItem key={id} sx={{ padding: "5px" }}>
  //             <img
  //               srcSet={`${item?.url[0]}`}
  //               src={`${item?.url[0]}`}
  //               alt={item.id}
  //               loading="lazy"
  //               onClick={() => openInNewTab(item?.url[0])}
  //             />
  //             <ImageListItemBar
  //               sx={{ margin: "5px" }}
  //               title={item.user?.displayName}
  //               subtitle={item.caption}
  //               actionIcon={
  //                 <IconButton
  //                   sx={{ color: "rgba(255, 255, 255)" }}
  //                   aria-label={"download"}
  //                   onClick={() => handleDownloadImage(item?.url[0], item.id)}
  //                 >
  //                   <CloudDownloadOutlinedIcon />
  //                 </IconButton>
  //               }
  //             />
  //           </ImageListItem>
  //         );
  //       })}
  //   </ImageList>
  // );
}
